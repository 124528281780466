:root {
  --phone-color: rgb(212 209 209);
  --content-color: rgb(146, 145, 145);
  --screen-color: #9b3c6f;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.center {
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 90px;
  height: 155px;
  transform: scale(0.5);
}

/* Smartphone */

.smartphone {
  width: 80px;
  height: 120px;
  margin: 40px 0 0 0;
  border: 5px var(--phone-color) solid;
  overflow: hidden;
  border-top-width: 15px;
  border-bottom-width: 20px;
  border-radius: 4px;
  animation: smartphone 5s ease-in-out forwards;
  animation-iteration-count: 2;
}

@keyframes smartphone {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  25% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(-90deg);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: rotate(-90deg);
    opacity: 0;
  }
}

.smartphone:before {
  content: '';
  display: block;
  width: 10px;
  height: 3px;
  margin: -9px auto;
  background: var(--phone-color);
  border-radius: 4px;
}

.smartphone:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  margin: 4px auto;
  background: var(--phone-color);
  border-radius: 10px;
}

.smartphone .content {
  width: 60px;
  height: 100px;
  margin: 15px 0 0 0;
  padding: 10px;
  background: var(--screen-color);
  color: var(--screen-color);
  font-size: 0.5em;
  overflow: hidden;
}

.smartphone ul li {
  height: 6px;
  margin: 0 0 5px 0;
  border-color: var(--screen-color);
  background: var(--screen-color);
}

.smartphone div {
  height: 40%;
  width: 100%;
  background-color: var(--content-color);
  position: absolute;
  top: 30%;
  left: 0;
  /* transform: translate(-50%, -50%); */
  animation: image 5s ease-in-out infinite;
}

@keyframes image {
  0% {
    top: 30%;
    transform: rotate(0deg);
    opacity: 0;
  }
  25% {
    top: 30%;
    opacity: 1;
    transform-origin: left;
    transform: rotate(0deg);
  }
  50% {
    top: 0;
    transform-origin: left;
    transform: rotate(90deg) translate(-25%, -60%);
  }
  60% {
    top: 0;
    transform-origin: left;
    transform: rotate(90deg) translate(-25%, -85%) scale(1.4);
  }
  75% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    transform-origin: left;
    transform: rotate(90deg) translate(-25%, -85%) scale(1.4);
    opacity: 0;
  }
}

.smartphone ul {
  animation: screen 5s ease-in-out infinite;
}

@keyframes screen {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  25% {
    opacity: 1;
    transform-origin: left;
    transform: rotate(0deg) translateY(0%) translateX(0%) scaleX(1);
  }
  50% {
    transform-origin: left;
    transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform-origin: left;
    transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
    opacity: 0;
  }
}