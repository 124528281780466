@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
:root {
  /* Base font size */
  font-size: 20px;
  --label-font-family: 'Lato', sans-serif;
  font-family: var(--label-font-family);
  /* Set neon color */
  --neon-text-color: #f40;
  --neon-border-color: #08f;
  --form-font-family: 'VT323', monospace;
  --form-font-family-prev: 'Syne Mono', monospace;
  --dark: #504a5d;
  --mid-light: #877e9a;
  --light: #f6f5eb;
  --active-filter: #d4efd4;
  --date-light: #88888852;
  --date-medium-light: #888888c2;
  --background-color: white;
  --secondary-color: #CFEBEE;
  --footer-color: #83dcd46b;
  --border-date: 1px solid var(--date-light);
  --nav-font-family: 'Roboto', sans-serif;
  --nav-font-size: 56px;
  --nav-font-weight: 900;
  --nav-font: var(--nav-font-weight) var(--nav-font-size) var(--nav-font-family);
  --logo-font-family: 'Squada One', cursive;
  --logo-font-size: 2rem;
  --logo-font-weight: 400;
  --logo-font: var(--logo-font-weight) var(--logo-font-size) var(--logo-font-family);
  --header-font-family: 'Amatic SC', cursive;
  --header-font-size: 40px;
  --header-font-weight: 700;
  --header-font: var(--header-font-weight) var(--header-font-size) var(--header-font-family);
}

body {
  font-size: 25px;
  font-family: var(--main-font)
}

.spinner-container {
  position: fixed;
  background-color: #504a5d;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.comming-soon {
  position: absolute;
  top: 100px;
  left: 50%;
  width: fit-content;
  transform: translateX(-50%);
  margin: 0 0 100px;
  font-family: var(--form-font-family);
}

.neon-header {
  /* margin: 0 auto; */
  font-size: 5rem;
  font-weight: 200;
  width: 300px;
  /* font-style: italic; */
  color: #fff;
  padding: 4rem 6rem 5.5rem;
  /* border: 0.4rem solid #fff; */
  border-radius: 2rem;
  text-transform: uppercase;
}

.neon-header::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.neon-header::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.neon-header:focus {
  outline: none;
}

/* Animate neon flicker */

.bonfire-spinner {
  animation: bonfire-mobile 1.2s infinite alternate-reverse ease;
  height: 200px;
  width: 200px;
  transform: scale(1, 1);
}

@media screen and (min-width: 700px) {
  .neon-header {
    /* margin: 0 auto; */
    font-size: 5rem;
    font-weight: 200;
    width: 300px;
    /* font-style: italic; */
    color: var(--mid-light);
    padding: 4rem 6rem 5.5rem;
    border-radius: 2rem;
    text-transform: uppercase;
  }
  .comming-soon {
    margin: 0 0 20px;
    width: fit-content;
    position: none;
  }
  .bonfire-spinner {
    height: 200px;
    width: 200px;
    transform: scale(2, 2);
    animation: bonfire 2s infinite alternate-reverse ease;
  }
  .bonfire-spinner-canvas {
    height: 200px;
    width: 400px;
    /* transform: scale(2, 2);
    animation: bonfire 2s infinite alternate-reverse ease; */
  }
  .e-mail-form {
    display: flex;
  }
}

@keyframes bonfire {
  0%, 50% {
    background-image: url("./bonfire.png/frame1.png");
  }
  10%, 60%, 80% {
    background-image: url("./bonfire.png/frame3.png");
  }
  20%, 40%, 70%, 100% {
    background-image: url("./bonfire.png/frame4.png");
  }
  30%, 90% {
    background-image: url("./bonfire.png/frame5.png");
  }
}

@keyframes bonfire-mobile {
  0% {
    background-image: url("./bonfire.png/frame1.png");
  }
  20% {
    background-image: url("./bonfire.png/frame3.png");
  }
  40%, 80% {
    background-image: url("./bonfire.png/frame4.png");
  }
  60%, 100% {
    background-image: url("./bonfire.png/frame5.png");
  }
}

@keyframes flicker {
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff, 0 0 2rem var(--neon-text-color), 0 0 4rem var(--neon-text-color), 0 0 6rem var(--neon-text-color), 0 0 8rem var(--neon-text-color), 0 0 10rem var(--neon-text-color);
    box-shadow: 0 0 .5rem #fff, inset 0 0 .5rem #fff, 0 0 2rem var(--neon-border-color), inset 0 0 2rem var(--neon-border-color), 0 0 4rem var(--neon-border-color), inset 0 0 4rem var(--neon-border-color);
  }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none;
  }
}

.avatar-painting {
  width: 400px;
  height: 400px;
  /* background-image: url("./bonfire.png/Avatar.png"); */
}

.e-mail-form {
  position: absolute;
  bottom: 100px;
  width: 400px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: space-between;
  padding: 20px;
  background-color: var(--mid-light);
  color: var(--dark);
  font-family: var(--form-font-family);
}

.e-mail-form-label {
  font-size: 15px;
  margin-right: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.e-mail-input {
  line-height: 30px;
  border-radius: 5px;
  padding: 0;
  font-size: 10px;
  background-color: transparent;
  text-indent: 10px;
  width: 100%;
  background-color: var(--dark);
  color: var(--light);
}

.e-mail-text {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.submit-btn {
  padding: 20px 30px;
  margin-top: 20px;
  font-family: var(--form-font-family);
  background-color: var(--dark);
  width: 100%;
  color: var(--light);
}

div::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

div, body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@-webkit-keyframes BackgroundGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes BackgroundGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes BackgroundGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
  pointer-events: all;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* html {
  scroll-behavior: smooth;
} */

[name$="2270"] {
  background-color: red;
  color: red;
}