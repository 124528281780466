.nav-link {
  height: fit-content;
  font-size: 16px;
  font: var(--nav-font);
  padding: 20px 30px;
  border: 1px solid;
  border-color: transparent;
  transition: 1s border-color ease;
}

.nav-link:hover {
  border-color: black;
  cursor: pointer;
}